import React, { useState } from "react"

//Utils
import {
  category_link,
  login_link,
  my_account_link,
  my_address_link,
  my_order_link,
  shop_link,
} from "components/links"
import router, { useRouter } from "next/router"
import { useSelector } from "react-redux"
import { parseCookies, setCookie } from "nookies"

//Components
import useConnectWallet from "lib/web3/hooks/useConnectWallet"
import { truncate } from "lib/web3/utils/helpers"
import ModalDisconnectWallet from "components/global/modal-disconnect-wallet"
import { Button } from "components/anti"

const MainDrawer = ({
  open,
  data,
  close,
  next,
  className = "",
  handleSelectMenu,
}) => {
  //Utils ************************************************************************************************** //
  const Router = useRouter()
  const currency = parseCookies()?.currency
  const { account } = useConnectWallet()
  const navExpand = "lg"
  //Utils ************************************************************************************************** //

  //States ************************************************************************************************* //
  const { categories, brands } = useSelector((state) => state.general)
  const { user_data } = useSelector((state) => state.auth)
  const [state, setState] = React.useState({
    categories,
  })
  const [modalDisconnect, setModalDisconnect] = useState(false)

  const filteredcategories = categories.filter(
    (item) => item.slug !== "coca-cola-merchandise"
  )
  //States ************************************************************************************************* //

  //Functions ********************************************************************************************** //
  const handleClick = (item) => {
    handleSelectMenu(item, () => next())
  }

  const handleNavigate = (url) => {
    Router.push("/")
    close()
  }

  const handleParseLink = () => {
    let newCategories = categories
    //Step 1
    newCategories.forEach((item) => {
      let mainSlug = `${shop_link}/${item.slug}-${item.id}`
      if (item?.children) {
        let newChildren = item?.children
        //Step 2
        newChildren.forEach((item2) => {
          if (item.title === "All") {
            item2.link = `${mainSlug}/${item2.slug}-${item2.id}`
          } else {
            item2.link = `${mainSlug}`
          }
          //Step 3
          let newChildren2 = item2.children
          if (item2.children) {
            newChildren2.forEach((item3) => {
              if (item3.title === "All") {
                item3.link = `${mainSlug}/${item2.slug}-${item2.id}`
              } else {
                item3.link = `${mainSlug}/${item2.slug}-${item2.id}/${item3.slug}-${item3.id}`
              }
            })
            if (!item2.children?.find((item) => item.title === "All")) {
              item2.children = [
                {
                  id: `${item2.slug}-${item2.id}`,
                  title: "All",
                  link: `${mainSlug}/${item2.slug}-${item2.id}`,
                },
                ...newChildren2,
              ]
            }
          }
        })

        //Set children level 2
        if (!item.children?.find((item) => item.title === "All")) {
          item.children = [
            {
              id: `${item.slug}-${item.id}`,
              title: "All",
              // link: `${category_link}/${item.slug}-${item.id}`
              link: `${shop_link}/${item.slug}-${item.id}`,
            },
            ...newChildren,
          ]
        }
        if (!item.children?.find((item) => item.title === "Special")) {
          item.children.push({
            id: `brand-${item.slug}-${item.id}`,
            title: "Special",
            children: brands
              ?.filter((brand) => brand.is_featured)
              ?.map((brand) => ({
                id: `brand-${brand.id}`,
                title: (
                  <>
                    <img src={brand.image} className="img-fluid" />
                    <p>{brand.title}</p>
                  </>
                ),
                link: `${shop_link}/brand-${brand.id}/${item?.slug}/${item?.id}`,
              })),
          })
        }
      }
      //Set link for parent
      item.link = `${mainSlug}`
    })
    setState((prev) => ({ ...prev, categories: newCategories }))
  }
  //Functions ********************************************************************************************** //

  //React Operations *************************************************************************************** //
  React.useEffect(() => {
    if (categories) {
      handleParseLink()
    }
  }, [categories])
  //React Operations *************************************************************************************** //
  return (
    <>
      <div
        className={`side-drawer-overlay ${open ? "open" : ""}`}
        onClick={() => close()}
      ></div>
      <div
        className={`side-drawer-mobile d-${navExpand}-none ${className} ${
          open ? "open" : ""
        }`}
      >
        <div className="close-button">
          <i onClick={close} className="air ai-times"></i>
        </div>
        {/* <div
            className="auth-link py-2 mt-4"
            onClick={() => handleNavigate(login_link)}
         >
            <p>Login/Register</p>
         </div> */}
        <div className="side-drawer-content">
          <ul className="nav-middle">
            {filteredcategories?.map((item) => (
              <li
                key={item.id}
                className="nav-item"
                onClick={() => handleClick(item)}
              >
                <div className="nav-link w-arrow">{item.title}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="side-drawer-other-links">
          <div
            className="nav-item pb-3"
            onClick={() =>
              handleClick(
                user_data
                  ? {
                      id: 0,
                      link: "/",
                      children: [
                        { title: "My Orders", link: my_order_link },
                        { title: "My Address", link: my_address_link },
                        { title: "Settings", link: my_account_link },
                        { title: "Log Out", link: "logout" },
                      ],
                    }
                  : { link: login_link }
              )
            }
          >
            <i className="air ai-user mr-2" />
            Profile
          </div>
          {account ? (
            <>
              <ModalDisconnectWallet
                id={true}
                show={modalDisconnect}
                hide={() => setModalDisconnect(false)}
              />
              <Button
                variant="outline-dark"
                className="w-100 nav-item wallet-address"
                onClick={() => setModalDisconnect(true)}
              >
                {truncate(account, 20)}
              </Button>
              {/* <div className="nav-item wallet-address pb-3" onClick={() => setModalDisconnect(true)}>
                     </div> */}
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default MainDrawer
